import Alpine from 'alpinejs'

document.addEventListener("DOMContentLoaded", () => {
    window.Alpine = Alpine;
    Alpine.start();
});

var kulturSearch = (kulturer = []) => {
    return {
        kulturer,
        search: '',
        filteredCultures() {
            return this.kulturer.filter(
                kultur => kultur.name.toLowerCase().includes(this.search.toLowerCase())
            );
        },
        highlightSearch(s) {
            if (this.search === '') return s;

            return s.name.replaceAll(
                new RegExp(`(${this.search.toLowerCase()})`, 'ig'),
                '<strong class="font-semibold bg-blue-200">$1</strong>'
            )
        }
    }
}

const sortSearch = (sorter = []) => {
    return {
        sorter,
        search: '',
        filteredSorter() {
            return this.sorter.filter(
                sort => sort.title.toLowerCase().includes(this.search.toLowerCase())
            );
        },
        highlightSearch(s) {
            if (this.search === '') return s;

            return s.name.replaceAll(
                new RegExp(`(${this.search.toLowerCase()})`, 'ig'),
                '<strong class="font-semibold bg-blue-200">$1</strong>'
            )
        }
    }
}

window.kulturSearch = kulturSearch;

window.sortSearch = sortSearch;
